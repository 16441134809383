import * as React from 'react';
import { Icon, IconNames } from '..';
import { classNames } from '../../lib';

export interface TabProps {
    name: string | JSX.Element;
    icon?: IconNames;
    active?: boolean;
    render?: () => any;
    disabled?: boolean;
    hidden?: boolean;
    pathname?: string;
    title?: string;
    indicator?: boolean;
    onClick?: (e: any) => void;
}

export const Tab: React.FC<TabProps> = ({ name, icon, active, onClick, disabled, title, indicator, hidden }) => {
    return hidden ? null : <li className={classNames({ 'is-active': active, disabled })} onClick={e => !disabled && onClick && onClick(e)}>
        <style jsx>{`
            .gap { padding: 0 .25em; }
            li.disabled a { color: #aaa; cursor: not-allowed; }
            li.disabled a:hover { border-color: #dbdbdb; }
            .indicator { margin-left: 1em; font-size: 0.5em; color: #C0504D; }
        `}</style>
        <a title={title}>
            {icon ? <Icon name={icon} sizeEm={1.25} /> : null}
            {icon && name ? <span className="gap" /> : null}
            {name}
            {indicator ? <span className="indicator fa fa-circle" /> : null}
        </a>
    </li>;
};
