import { auth } from 'web-shared/store';
import { isDevelopment } from '.';

interface Location {
	pathname: string;
	search: string;
	state: any;
	hash: string;
}

let currentClientId: number = 0;
let googleAnalyticsCodes: string[] = [];
const enabled = () => googleAnalyticsCodes.length > 0 // & !isDevelopment;
export function initializeGa(clientId: number, gaCode: string) {
	currentClientId = clientId;
	googleAnalyticsCodes = gaCode.split(',').map(c => c?.trim() || '').filter(c => c);
}

function gaConfig() {
	if(!enabled() || !window.gtag)
		return;

	const linker = currentClientId === 1032 ? { linker: { domains: ['ucbi.com'] } } : {};
	const user_id = auth.getStateSnapshot().userName;

	googleAnalyticsCodes.forEach(gaCode => {
		window.gtag('config', gaCode, {
			send_page_view: false,
			...linker,
		});
	});
	window.gtag('set', { user_id });
}

export function gaPageView(location: Location) {
	if(!enabled() || !window.gtag)
		return;

	gaConfig();
	window.gtag('event', 'page_view', {
		page_path: location.pathname + location.search + location.hash,
		page_search: location.search,
		page_hash: location.hash,
	});
}
export function gaEvent(category: string, action: string) {
	if(!enabled() || !window.gtag)
		return;

	gaConfig();
	window.gtag('event', action, { category });
}
export function gaUserCreate() {
	if(!enabled() || !window.gtag)
		return;

	gaConfig();
	window.gtag('event', 'sign_up', { });
}
export function gaLogin() {
	if(!enabled() || !window.gtag)
		return;

	window.gtag('event', 'login', {});
}
