import * as React from 'react';
import equals from 'fast-deep-equal';
import { produce, Draft, Patch } from 'immer';
import moize from 'moize';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { formatNumber, dbg, isTin, Failable } from 'web-shared/lib';
import { isDevelopment } from './isDevelopment';
import { isPoBox } from './isPoBox';
import { isDateValid } from './format';
import { TabError } from 'web-shared/components';

const withDevTools = isDevelopment
    && typeof window !== 'undefined'
    && (window as any).__REDUX_DEVTOOLS_EXTENSION__;

export type ApiState = { working?: boolean; error?: string };
export type ActionState = { ui: { actions: { [action: string]: ApiState } } };
export type ActionFn<RootState, Payload, FailurePayload> = (s: RootState) => Promise<Failable<Payload, FailurePayload>>;
export type PatchFilter = (patche: Patch, index: number, patches: Patch[]) => boolean;

export class RxStore<RootState extends ActionState, ComputedState extends { state: RootState | undefined } = { state: undefined }> {
    private stateSubject: BehaviorSubject<RootState>;
    private computed: ComputedState | undefined;
    private devTools: any;
    private patchFilter: PatchFilter | undefined;
    public patches: Patch[];

    // Initialize the store with the initial state value.
    constructor(initialState: RootState, name: string, computed?: ComputedState, patchFilter?: PatchFilter) {
        this.stateSubject = new BehaviorSubject(initialState);
        this.computed = computed;
        this.patchFilter = patchFilter;
        this.patches = [];
        if(withDevTools) {
            this.devTools = withDevTools.connect({ name });
            this.devTools.init(initialState);
            this.devTools.subscribe((m: any) => {
                if(m.type === 'DISPATCH') {
                    switch(m.payload?.type) {
                        case 'JUMP_TO_STATE':
                        case 'JUMP_TO_ACTION':
                            this.stateSubject.next(JSON.parse(m.state));
                            break;
                        default:
                            //console.log('DEVTOOLS:', m);
                            break;
                    }
                }
            });
        }
    }

    // Get the current state as a stream
    public getState(): Observable<RootState> {
        return this.stateSubject.pipe(distinctUntilChanged());
    }

    // Get the current state as a snapshot
    public getStateSnapshot(): RootState {
        return this.stateSubject.getValue();
    }

    public update(fn: (s: Draft<RootState>) => void, action?: string) {
        const nextState = produce(this.getStateSnapshot(), fn
            , this.patchFilter ? _patches => {
                // const filteredPatches = patches.filter(this.patchFilter);
                // if(filteredPatches.length)
                //     console.log(filteredPatches);
            } : undefined
        );
        if(isDevelopment && this.devTools)
            this.devTools.send(action || 'unknown action', nextState);
        this.stateSubject.next(nextState);
    }

    public async action<Payload, FailurePayload = {}>(actionName: keyof RootState['ui']['actions'], action: ActionFn<RootState, Payload, FailurePayload>): Promise<Failable<Payload, FailurePayload>> {
        const actionState = this.getStateSnapshot().ui.actions[actionName as any];
        if(actionState.working)
            return { isError: true, error: 'Action is in progress.' } as any;

        // Set action state to working
        this.update(s => {
            const actionState = s.ui.actions[actionName as any];
            actionState.error = '';
            actionState.working = true;
        }, `${actionName.toString()}_begin`);

        // Execute action
        const response = await action(this.getStateSnapshot());

        // Handle action state from response
        this.update(s => {
            const actionState = (s.ui.actions as any)[actionName];
            actionState.error = response.isError ? response.error : undefined;
            actionState.working = false;
        }, actionName.toString() + (response.isError ? '_error' : '_success'));

        return response;
    }

    // Merge in a new state object
    public setState(partialState: Partial<RootState>): void {
        let currentState = this.getStateSnapshot();
        let nextState = Object.assign({}, currentState, partialState);

        this.stateSubject.next(nextState);
    }

    // Subscribe to a portion of state values
    public useState<S>(selector: (state: RootState, computed: ComputedState) => S) {
        if(!selector)
            throw new Error('Selector fn is not provided.');

        let id = '';
        if(isDevelopment) {
            try {
                const stackLine = new Error().stack?.split('\n')[2];
                const match = /at\s+(\w+)/g.exec(stackLine || '');
                id = match?.[1] || '';
            } catch { }
        }

        const computed = this.computed || { state: {} } as ComputedState;
        computed.state = this.stateSubject.value;
        const [val, setVal] = React.useState(() => selector(this.stateSubject.value, computed));
        let getCurrentState = () => val;

        React.useLayoutEffect(() => {
            const selectSubscription = this.stateSubject.pipe(
                map(s => {
                    computed.state = s;
                    return selector(s, computed);
                }),
                distinctUntilChanged(),
            ).subscribe(s => {
                const currentState = getCurrentState();
                if(!equals(s, currentState)) {
                    if(isDevelopment)
                        dbg('Subscription', id, '#FFDC5D', currentState, '->', s);
                    getCurrentState = () => s;
                    setVal(s);
                }
            });
            return () => { selectSubscription.unsubscribe(); };
        }, [selector.toString()]);

        return val;
    }

    // Helper for binding form fields to state values
    public useForm(formOptions: FormOptions<RootState> = {}) {
        const bindField = this.bindField.bind(this);
        const formState = React.useState<LocalFormState>({
            values: {},
            errors: {},
            required: {},
            updating: {},
            focused: '',
            subscriptions: {},
            submitAttempted: false,
        });
        const formStateRef = React.useRef<LocalFormState>(formState[0]);

        // Need to check all required fields plus extra validation
        const fs = formState[0];
        formStateRef.current = fs;
        const keys = Object.keys(fs.values);
        const isValid = keys.length > 0 && keys.every(k => {
            return (!fs.required[k] || !!fs.values[k]) && !fs.errors[k];
        });

        const updateFn = React.useRef<(key: string, value: any) => void>(() => { });
        let fields: { [k: string]: (nv: any) => void };
        const addField = moize(function (fieldProps: BoundField) {
            const { key, updateRxState, ...props } = fieldProps;
            fields = { ...fields, [key]: updateRxState };
            return props;
        });

        // Destroy subscriptions on unmount
        React.useEffect(() => {
            return () => {
                Object.keys(fs.subscriptions).map(k => {
                    fs.subscriptions[k]?.unsubscribe();
                });
            };
        }, []);

        // Return the field binding helpers
        return {
            isValid,
            submit: (fn?: () => void) => {
                return (e: React.FormEvent) => {
                    e.preventDefault();
                    e.stopPropagation();

                    // See if we need to update the curently focused field
                    const { focused, values } = formState[0];
                    if(focused) {
                        fields[focused](values[focused]);
                    }

                    // Mark the form with a submit attempt to show all errors
                    formState[1](s => ({
                        ...s,
                        errors: keys.reduce((errors, k) => {
                            errors[k] = errors[k] ? errors[k] : s.required[k] && !s.values[k] ? 'This field is required.' : '';
                            return errors;
                        }, s.errors),
                        submitAttempted: true,
                    }));
                    if(isValid) {
                        fn && fn();
                    }
                };
            },
            onChange: (fn: (key: string, value: any) => void) => { updateFn.current = fn; },
            formState: fs,

            bindText(selector: StateSelector<RootState, string> | string[], options?: BindOptions<RootState>) {
                return addField(bindField({ updateFn, selector, formState, formStateRef, options: { ...formOptions, type: 'text', ...options } }));
            },
            bindNumeric(selector: StateSelector<RootState, number> | string[], options?: BindOptionsNumeric<RootState>) {
                return addField(bindField({
                    updateFn, selector, formState, formStateRef,
                    options: { ...formOptions, ...options, type: 'numeric' },
                    parse: (v: string) => {
                        return v === ''
                            ? undefined
                            : v.trim() === '-' ? -0 : Number(v.replace(options?.decimals! > 0 ? /[^0-9\-\.]/g : /[^0-9\-]/g, ''));
                    },
                    format: (v: number | undefined, key?: string) => {
                        const formatted =  v == null || v.toString() === ''
                            ? ''
                            : (options?.currency ? '$' : '') + formatNumber(v, options?.decimals || 0, false) + (options?.percentage ? '%' : '');
                        return options?.comma === false ? formatted.replace(',', '') : formatted;
                    },
                }));
            },
            bindBoolean(selector: StateSelector<RootState, boolean> | string[], options?: BindOptions<RootState>) {
                return addField(bindField({
                    updateFn, selector, formState, formStateRef,
                    options: { ...formOptions, ...options },
                    parse: (v: string | boolean) => v === 'true' || v === true ? true : v === 'false' || v === false ? false : undefined,
                    // format: (v: boolean) => v === true ? 'true' : v === false ? 'false' : undefined,
                }));
            },
            bindDate(selector: StateSelector<RootState, string> | string[], options?: BindOptions<RootState>) {
                return addField(bindField({
                    updateFn, selector, formState, formStateRef, options: { ...formOptions, ...options, type: 'date', placeholder: 'M/D/YYYY' },
                    // format: (v: string) => v ? formatLocalDate(v, 'yyyy-MM-dd') : '',
                    // parse: (v: string) => v ? formatLocalDate(v) : undefined,
                }));
            },
        };
    }

    bindField({ updateFn, selector, format, parse, formState, formStateRef, options }: BindFieldProps<RootState>): BoundField {
        const { mask, index, index2, required, type = 'text', defaultValue } = options || {};
        const isSelectorFn = typeof selector === 'function';
        const name = isSelectorFn
            ? parseFn(selector)[1].trim()
            : selector.join('.');
        // console.log({name})

        const key = `${name}|${index ?? ''}`.trim()
            + (index2 == undefined ? '' : `|${index2}`);
        const [state, setState] = formState;
        const isFocused = state.focused === key;

        let v = state.values[key];

        // Set the default value for local state
        const value = format ? format(v, key) : v;
        const maskRegex = mask ? new RegExp(mask) : null;

        // Update Rx store
        const updateRxState = (nv: any) => {
            this.update(s => {
                // Prevent updates when there is no change
                const currentStateValue = isSelectorFn
                    ? selector(s as RootState, (index || 0) as any, (index2 || 0) as any) ?? ''
                    : getSelector(selector, s as RootState, index, index2);
                if(currentStateValue === (nv ?? '')) return;

                // dbg('updateRxFn', key, '#FFEDAC', { currentStateValue, nv });
                updateSelector(selector, s, index, index2, parse ? parse(nv) : nv);
            }, key);
        };

        // Update local state
        const updateLocalState = (value: any, updateRx?: boolean, externalChange?: boolean) => {
            formStateRef.current.updating[key] = true;

            // dbg('updateLocalState', key, 'purple', { value, updateRx });
            if(maskRegex && (value || '').replace(maskRegex, '') !== value) {
                return;
            }

            // Try our mask function
            const [maskedValue, error] = maskValue(type, value, options);
            if(maskedValue === null) {
                return;
            }

            updateRx && updateRxState(maskedValue);

            // Update form state
            setState(s => {
                const ret = {
                    ...s,
                    values: { ...s.values, [key]: maskedValue },
                    errors: { ...s.errors, [key]: error },
                };
                return ret;
            });

            // Notify the component that the form changed.
            if(updateFn.current && !externalChange) {
                // dbg('updateFn', key, '#F79646', { value, updateRx });
                setTimeout(() => updateFn.current(key, value), 100);
            }

            formStateRef.current.updating[key] = false;
        };

        // Subscribe to state changes
        if(!state.subscriptions[key]) {
            const selectSubscription = this.stateSubject.pipe(
                map(v => {
                    try {
                        return isSelectorFn
                            ? selector(v, index as any, index2 as any)
                            : getSelector(selector, v, index, index2);
                    } catch(ex) {
                        if(formStateRef.current.subscriptions[key])
                            formStateRef.current.subscriptions[key]?.unsubscribe();
                        else
                            dbg('Unsubscribe Failed', key, 'red', formStateRef.current.subscriptions[key]);
                        setState(s => ({ ...s, subscriptions: { ...s.subscriptions, [key]: undefined } }));
                    }
                }),
                distinctUntilChanged(),
            ).subscribe(nv => {
                const v = formStateRef.current.values[key];
                // tslint:disable-next-line: triple-equals
                if((v ?? '') !== (nv ?? '') && !formStateRef.current.updating[key]) {
                    // dbg('Form Subscription', key, '#FFEDAC', { v, nv });
                    updateLocalState(nv?.toString(), undefined, true);
                }
            });

            // Add this subscription to the form state
            setState(s => {
                return { ...s, subscriptions: { ...s.subscriptions, [key]: selectSubscription } };
            });
        }

        let inputType = type;
        switch(type) {
            case 'password':
                inputType = 'password';
                break;
            case 'checkbox':
                inputType = 'checkbox';
                break;
            default:
                inputType = 'text';
                break;
        }
        if(options?.hide && !isFocused)
            inputType = 'password';

        const error = options.allErrors?.find(e => e && e.field && name === e.field && (e.i == index || e.i === -1) && (e.j == index2 || e.j === -1));
        // if(name === 's.owners[i].employment.employerName')
        //     console.log({ name, error, index, index2, allErrors: options.allErrors });
        const errorMsg = error?.msg ? error.msg : state.errors[key] || (error ? '' : undefined);
        const numericOptions = options as BindOptionsNumeric<RootState>;
        const style: React.CSSProperties = {};
        if(numericOptions?.currency || numericOptions?.percentage)
            style.textAlign = 'right';
        if(options.align)
            style.textAlign = options.align;
        const valueKey = type === 'checkbox' ? 'checked' : 'value';

        return {
            key,
            placeholder: options.placeholder,
            updateRxState,
            type: inputType,
            [valueKey]: type === 'checkbox' ? (v === 'true' || v === true) : !v && defaultValue ? defaultValue : value === undefined || v === undefined ? '' : isFocused ? v : value,
            onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, valueOverride?: any) => {
                const value = 'checked' in e.target && type === 'checkbox' ? e.target.checked : e.target.value;
                const updateRx = options?.update === 'onChange' || !updateOnBlur(e.target);
                updateLocalState(valueOverride !== undefined ? valueOverride : value, updateRx);
            },
            onFocus: e => {
                setState(s => ({ ...s, focused: key }));
                if('select' in e.target) {
                    const t = e.target;
                    setTimeout(() => {
                        if(t === document.activeElement)
                            t.select();
                    }, 0);
                }
            },
            onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
                if(!options?.disableOnBlur) {
                    setState(s => ({ ...s, focused: '' }));
                }
                if(options?.update !== 'onChange' && updateOnBlur(e.target)) {
                    const value = 'checked' in e.target && type === 'checkbox' ? e.target.checked : e.target.value;
                    updateRxState(value);
                }
            },
            required,
            'aria-required': required,
            'data-error': errorMsg,
            // tslint:disable-next-line: triple-equals
            'aria-invalid': errorMsg != undefined,
            style,
            title: error?.msg,
            autoComplete: 'sfsf9uhef3',
        };
    }
}

function updateOnBlur(element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) {
    return element?.nodeName.toLowerCase() === 'input'
        && element.type !== 'radio' && element.type !== 'checkbox';
}

function maskValue<S>(type: InputType, value: string, options: BindOptions<S> | BindOptionsNumeric<S>): [null | string, string] {
    let v = value === undefined ? '' : value;
    let e = '';
    switch(type) {
        case 'date':
            if(v.match(/[^0-9\/]/) !== null || v.replace(/[^0-9]/g, '').length > 8 || v.replace(/[^\/]/g, '').length > 2)
                return [null, e];
            if(v.match(/^\d\d\d$/))
                v = v.substring(0, 2) + '/' + v.substring(2, 3);
            if(v.match(/^\d\d\/\d\d\d$/))
                v = v.substring(0, 5) + '/' + v.substring(5, 6);
            e = isDateValid(v) ? '' : 'Not a valid date (mm/dd/yyyy)';
            break;
        case 'ssn':
            if(v.match(/[^0-9\-]/) !== null || v.replace(/[^0-9]/g, '').length > 9 || v.replace(/[^\-]/g, '').length > 2)
                return [null, e];
            if(v.match(/^\d\-/) !== null || v.match(/^\d\d\-/) !== null || v.match(/^\d\d\d\-\-/) !== null || v.match(/^\d\d\d\-\d\-/) !== null)
                return [null, e];
            if(v.match(/^\d\d\d\d$/))
                v = v.substring(0, 3) + '-' + v.substring(3, 4);
            if(v.match(/^\d\d\d-\d\d\d$/))
                v = v.substring(0, 6) + '-' + v.substring(6, 7);
            e = v.replace(/[\s_-]/g, '').length === 9 ? '' : 'SSN is not valid';
            break;
        case 'ssn4':
            if(v.match(/[^0-9]/) !== null || v.length > 4)
                return [null, e];
            e = v.length === 0 ? 'This field is required' : v.length < 4 ? 'Please enter the last 4 digits of your SSN' : '';
            break;
        case 'physicalAddress':
            e = isPoBox(value) ? 'The business address must be a physical address.' : '';
            break;
        case 'tin':
            if(v.replace(/[^0-9]/g, '').length > 9)
                return [null, e];
            e = isTin(v) ? '' : 'Not a valid EIN or SSN';
            break;
        case 'ein':
            if(v.match(/[^0-9\-]/) !== null || v.replace(/[^0-9]/g, '').length > 9 || v.replace(/[^\-]/g, '').length > 1)
                return [null, e];
            if(v.match(/^\d\-/) !== null)
                return [null, e];
            if(v.match(/^\d\d\d$/))
                v = v.substring(0, 2) + '-' + v.substring(2, 3);
            e = v.replace(/[\s_-]/g, '').length === 9 ? '' : 'EIN is not valid';
            break;
        case 'phone':
            if(v.match(/[^0-9\-]/) !== null || v.replace(/[^0-9]/g, '').length > 10 || v.replace(/[^\-]/g, '').length > 2)
                return [null, e];
            if(v.match(/^\d\d\d\d$/))
                v = v.substring(0, 3) + '-' + v.substring(3, 4);
            if(v.match(/^\d\d\d-\d\d\d\d$/))
                v = v.substring(0, 7) + '-' + v.substring(7, 8);
            e = v.replace(/[^0-9]/g, '').length === 10 ? '' : 'Phone number is not valid';
            break;
        case 'numeric':
            const numericOptions = options as BindOptionsNumeric<S>;
            try {
                v = numericOptions.positive
                    ? v.replace(/[^0-9\.]/g, '')
                    : v.replace(/[^0-9\.\-]/g, '');
                if(!numericOptions.positive && v?.trim() === '-')
                    break;

                const num = Number(v);
                if(isNaN(num)) {
                    return [null, 'Invalid number'];
                }

                if(v !== '' && numericOptions.min !== undefined) {
                    if(num < numericOptions.min) {
                        return [v, `Must be at least ${numericOptions.min}`];
                    }
                }
                if(v !== '' && numericOptions.max !== undefined) {
                    if(num > numericOptions.max) {
                        return [v, `Cannot be more than ${numericOptions.max}`];
                    }
                }

                const [whole, dec] = v.split('.');
                if((numericOptions.decimals || 0) === 0) {
                    v = whole;
                } else if(dec !== undefined) {
                    v = `${whole}.${dec.substring(0, numericOptions.decimals)}`;
                }
            } catch(e) {
                return [null, 'Invalid number'];
            }
            break;
        default:
            break;
    }
    return [v, e];
}

type InputType = 'text' | 'checkbox' | 'date' | 'email' | 'ssn' | 'ssn4' | 'password' | 'accountNumber' | 'physicalAddress' | 'phone' | 'numeric' | 'tin' | 'ein';
type LocalFormState = {
    values: { [k: string]: any };
    errors: { [k: string]: string };
    required: { [k: string]: boolean };
    updating: { [k: string]: boolean };
    subscriptions: { [k: string]: Subscription | undefined };
    focused: string;
    submitAttempted: boolean;
};
export type StateSelector<State, T> = (state: State, i: number, j: number) => T | undefined;
type BindFieldProps<State> = {
    formState: [LocalFormState, React.Dispatch<React.SetStateAction<LocalFormState>>];
    formStateRef: React.MutableRefObject<LocalFormState>;
    selector: StateSelector<State, any> | string[];
    format?: (value: any, key?: string) => string,
    parse?: (value: string) => any,
    options: BindOptions<State>;
    updateFn: React.MutableRefObject<(key: string, value: any) => void>
};
type BindOptions<S> = {
    mask?: string;
    hide?: boolean;
    index?: number | string;
    index2?: number | string;
    type?: InputType;
    required?: boolean;
    placeholder?: string;
    update?: 'onBlur' | 'onChange';
    disableOnBlur?: boolean;
    allErrors?: TabError<S>[];
    defaultValue?: string;
    align?: React.CSSProperties['textAlign'];
};
type BindOptionsNumeric<S> = BindOptions<S> & {
    decimals?: number;
    min?: number;
    max?: number;
    currency?: boolean;
    percentage?: boolean;
    positive?: boolean;
    defaultValue?: number;
    comma?: boolean;
};
export type BoundFieldProps = {
    type: InputType;
    value?: any;
    checked?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, valueOverride?: any) => void;
    onFocus: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    required?: boolean;
    placeholder?: string,
    'aria-required'?: boolean;
    'aria-invalid': boolean;
    'data-error': string | undefined;
    style: any;
    title?: string;
    autoComplete?: string;
};
type BoundField = BoundFieldProps & {
    key: string;
    updateRxState: (nv: any) => void;
};

function parseFn(selector: Function | string) {
    // Sanitize the fn string
    const fnString = selector.toString()
        .replace(/[\r\n]/g, '')
        .replace(/\s\s/g, ' ');

    // Parse a full function body
    if(fnString.startsWith('function')) {
        const fnRegex = new RegExp('\\(([^\)]*)\\).*return\s*([^;}]*)', 'gi');
        const result = fnRegex.exec(fnString);
        if(!result)
            return ['', ''];
        const p = (result[1] || '').trim();
        const f = (result[2] || '').trim();
        return [p, f];
    }

    // Parse a lambda
    const [params, body] = fnString.split('=>').map(p => p.trim());
    const stateVars = params.replace('(', '').replace(')', '');
    return [stateVars, body];
}

export function executeSelector<T>(selector: (string|number)[] | Function, state: {}, i?: string | number, i2?: string | number) : T {
    return typeof selector === 'function'
        ? selector(state, i, i2)
        : getSelector(selector, state, i, i2);
}
export function getSelector(parts: (string|number)[], data: {}, i: string | number | undefined, j: string | number | undefined): any {
    let o: any = data;
    const normalizedParts = parts.join('.')
        .replace('[i]', `.${i}`)
        .replace('[i2]', `.${j}`)
        .replace('[j]', `.${j}`)
        .split('.');

    for(let i = 1, n = normalizedParts.length; i < n; ++i) {
        const k = normalizedParts[i];
        if(k in o) {
            o = o[k];
        } else {
            return undefined;
        }
    }
    return o;
}

export function getSelectorPaths(selector: Function | string, i?: string | number, i2?: string | number) {
    const [, fnBody] = parseFn(selector)
    return replaceIndexParts(fnBody, i, i2)
        .flatMap(v => v.trim().split('['))
        .map(v => v.replace(']', ''));
}
function replaceIndexParts(selector: string | string[], i: string | number | undefined, i2: string | number | undefined) {
    const parts = typeof selector === 'string' ? selector.split('.') : selector;
    return parts
        .map(v => v
        .replace('[i]', `.${i}`)
        .replace('[i2]', `.${i2}`)
        .replace('[j]', `.${i2}`))
        .join('.')
        .split('.');
}
export function updateSelector(selector: Function | string | string[], data: {}, i: number | string | undefined, i2: number | string | undefined, v: any) {
    const parts = typeof selector === 'function'
        ? getSelectorPaths(selector, i, i2)
        : replaceIndexParts(selector, i, i2);

    // Skip the first
    parts.shift();

    let o: any = data;
    while(parts.length - 1) {
        const n = parts.shift() ?? '';
        if(!(n in o)) {
            // o[n] = {};
            console.log('Object missing:', { selector, data: JSON.parse(JSON.stringify(data)), i, i2, v, n, o: JSON.parse(JSON.stringify(o)), 'o[n]': o[n] });
            return;
        }
        o = o[n];
    }
    o[parts[0]] = v;
}
export interface FormOptions<S> {
    required?: boolean;
    update?: 'onBlur' | 'onChange';
    disableOnBlur?: boolean;
    allErrors?: TabError<S>[];
}
