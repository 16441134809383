export type LoginData = {
    isError: false;
    access_token: string;
    token_type: string;
    '.issued': string;
    '.expires': string;
    userName: string;
    name: string;
    id: string;
    Admin?: string;
    GlobalAdmin?: string;
    ClientId: string;
    LenderAssisted?: string;
};

export interface LoginCredentials {
    email: string;
    password: string;
    remember: boolean;
    clientId?: number;
}

const loginDataKey = 'loginDataKey';
const branchLoginDataKey = 'branchLoginDataKey';

if(typeof window === 'undefined') {
    (global as any).window = {};
}

export function getBranchLoginData(): LoginData {
    try {
        return JSON.parse(getTokenStorage().getItem(branchLoginDataKey) || '{}');
    } catch(e) {
        return JSON.parse('{}');
    }
}
export function setBranchLoginData(data: LoginData) {
    let storage: Storage;
    try {
        storage = localStorage.getItem('rememberLogin') === 'true' ? localStorage : sessionStorage;
    } catch(err) {
        storage = getFakeStorage();
    }

    storage.setItem(branchLoginDataKey, JSON.stringify(data));
}
export function clearBranchLoginData() {
    try {
        const fakeStorage = getFakeStorage();

        // Clear branch login
        fakeStorage.removeItem(branchLoginDataKey);
        sessionStorage.removeItem(branchLoginDataKey);
        localStorage.removeItem(branchLoginDataKey);
    } catch(err) { }
}
export function getBranchUserToken(branchLoginData?: LoginData) {
    // Get the token and expiration
    const data = branchLoginData || getBranchLoginData();

    const tokenExpirationString = data['.expires'];
    const tokenExpiration = tokenExpirationString ? new Date(tokenExpirationString) : new Date();

    if(data.access_token == null || tokenExpiration <= new Date()) {
        return null;
    }

    return data.access_token;
}

/** Get the login info found in localStorage or sessionStorage */
export function getLoginData(): LoginData {
    try {
        return JSON.parse(getTokenStorage().getItem(loginDataKey) || '{}');
    } catch(e) {
        return JSON.parse('{}');
    }
}

/** Store login info in localStorage or sessionStorage as well as a cookie */
export function setLoginData(data: LoginData, remember: boolean) {
    // Store in a cookie
    document.cookie = `api_key=${data.access_token}; expires=${data['.expires']}; path=/`;

    // Store in storage
    let storage: Storage;
    try {
        storage = remember ? localStorage : sessionStorage;
    } catch(err) {
        storage = getFakeStorage();
    }

    storage.setItem(loginDataKey, JSON.stringify(data));
    localStorage.setItem('rememberLogin', remember.toString());
}

/** Clear all authentication data from localStorage and sessionStorage */
export function clearLoginData() {
    // Store in a cookie
    document.cookie = `api_key=; expires=; path=/`;

    try {
        const fakeStorage = getFakeStorage();

        // Clear regular login
        fakeStorage.removeItem(loginDataKey);
        sessionStorage.removeItem(loginDataKey);
        localStorage.removeItem(loginDataKey);
    } catch(err) { }
}

/** Extract the access token from stored login data */
export function getToken(loginData?: LoginData, ignoreExpires?: boolean) {
    // Get the token and expiration
    const data = loginData || getLoginData();

    const tokenExpirationString = data['.expires'];
    const tokenExpiration = tokenExpirationString ? new Date(tokenExpirationString) : new Date();

    if(data.access_token == null || (!ignoreExpires && tokenExpiration <= new Date())) {
        return null;
    }

    return data.access_token;
}

export function getFakeStorage(): Storage {
    if(!(window as any).fakeStorage) {
        (window as any).fakeStorage = {} as any;
        (window as any).fakeStorage.setItem = (key: string, value: string) => (window as any).fakeStorage[key] = value;
        (window as any).fakeStorage.getItem = (key: string) => (window as any).fakeStorage[key];
        (window as any).fakeStorage.removeItem = (key: string) => delete (window as any).fakeStorage[key];
        (window as any).fakeStorage.clear = () => { };
        (window as any).fakeStorage.key = (_index: number) => '';
    }
    return (window as any).fakeStorage;
}
export function getLocalStorage(): Storage {
    try {
        return localStorage;
    } catch(err) {
        return getFakeStorage();
    }
}
export function getTokenStorage(): Storage {
    try {
        return sessionStorage.getItem(loginDataKey) ? sessionStorage : localStorage;
    } catch(err) {
        return getFakeStorage();
    }
}
